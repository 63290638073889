.menuIcon {
  display: none;
  font-size: 1.5rem;
  transition: all 0.5s ease-in-out;
}

.menuList {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
  margin: 0 !important;
  list-style-type: none;


}


@media screen and (max-width: 1024px) {

  .navbar-right .lists {
    padding: 0;
    display: flex;
    flex-direction: column;
    /* overflow: hidden; */
  }


  .menuIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #000;
  }

  .menuList {
    background-color: #ffffffd6 !important;
    left: 0;
    position: absolute;
    width: 100%;
    height: 98%;
    top: 4.5rem !important;
    right: 0 !important;
    transition: all 0.5s ease-in-out;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }

  .menuList.close {
    padding: 0;
    top: -100% !important;

    transition: all 0.5s ease-in-out;
  }
}

.navmenu {
  display: flex;
  gap: 1rem;

}

.dropbtn {
  cursor: pointer;
  padding: 1rem .3rem;
}

.dropdownOne {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdowncontent {

  border-top: 5px solid #FFD93D;
  display: none;
  position: absolute;
  left: auto;
  box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
    0 4px 6px -2px rgba(71, 63, 79, 0.16);
  z-index: 99;
  width: max-content;
  background-color: #fff;
  border-radius: 0 0 0.5rem 0.5rem;
  height: 0;
  transition: height ease 0.2s;
}

.dropdowncontent:after {
  content: '';
  position: absolute;
  top: 0;
  left: 10%;
  margin-left: 5px;
  margin-top: -15px;
  width: 0;
  z-index: 1;
  height: 0;
  border-bottom: solid 15px #FFD93D;
  border-left: solid 15px transparent;
  border-right: solid 15px transparent;
}

/* Links inside the dropdown */
.dropdowncontent a {

  padding: 5px 0;
  text-decoration: none;
  display: block;
  font-size: 16px;
}

/* Change color of dropdown links on hover */
.dropdowncontent a:hover {
  background-color: #f1f1f1
}

.dropdownOne:hover .dropdowncontent {

  display: flex;
  gap: 2rem;
  padding: 1rem;
  height: fit-content;

}

.menuIconMobile {
  display: none;
}
.location{
  font-weight: 400;
}
@media (max-width:767px) {
  .menuIconMobile {
    position: absolute;
    left: 4%;
    top: 2%;
    background: gold;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    border-radius: 50%;
  }

  .mobileRes {
    display: none !important;
  }

  .dropdownOne:hover .dropdowncontent {
    display: none;
  }

  .arrowa {
    display: none !important;
  }

  .dropbtn {
    cursor: pointer;
    padding: 0;
  }

  .navBar {
    background-color: #FFD93D !important;
    height: 0 !important;
  }

  .menuList {
    position: fixed !important;
    display: flex;
    align-items: flex-start;
    width: 100%;
    top: 0rem !important;
    background: #ffffffd6 !important
  }

  .navBar .navbar-container .navbar-right .lists li {
    padding: 0;
  }

  .navBar .navbar-container .navbar-right .lists {

    width: 80%;
  }
}

.location-heading {
  font-weight: 600;
}

.location {
  padding: 0.2rem 0;
}

.mybtn {
  color: #000;
  outline: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  text-align: center;
}

.mybtn:hover {
  background-color: #FFD93D !important;
}

.multidropdown {
  padding: 1rem;
}

.arrowa {
  width: 0.5em;
  height: 0.5em;
  display: inline-block;
  vertical-align: middle;
  border-left: 0.15em solid currentColor;
  border-bottom: 0.15em solid currentColor;
  transform: rotate(-45deg);
  margin-left: 0.38em;
  margin-top: -0.25em;
  transition: transform 100ms ease-in-out;
}

li:focus-within>.arrowa {
  transform: rotate(-225deg);
  margin-top: 4px;
}

li:hover .arrowa {
  transform: rotate(-225deg);
  margin-top: 4px;
}

li:focus-within .dropdowncontent {
  height: fit-content;
}

ul li:hover .dropdowncontent {
  height: fit-content;
}