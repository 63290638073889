.simple-bookings-page-container {
    height: auto;
    width: 100%;
    max-width: 1180px;
    margin: 0 auto;

    .simple-bookings-page-wrapper{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
            justify-content: center;
            align-items: center;
            

        .simple-bookings-text-container{
            width: 100%;
            height: auto;
            display: flex;
            // justify-content: center;
            // align-items: center;
            flex-direction: column;
            gap: 1rem;
            line-height: 1.5rem;
          

            

            .heading{
                font-size: 2rem;
                font-weight: 600;
                color: #00c2ff;


            }
            .no-booking-heading{
                font-size: 2rem;
            font-weight: 600;
            color: #00c2ff;
            }
           
        }
        .booking-cards-container{
            display: flex;
            gap: 1rem;
            width: 100%;
            height: auto;
            flex-wrap: wrap;
            @media (max-width:767px) {
                gap: 0.5rem !important;
                
            }

            .booking-cards{

                width: 40%;
                height: auto;
                margin: 0.5rem 0;

                @media (max-width:767px) {
                    width: 100% !important;
                    padding:.5rem !important ;
                    
                }

                .booking-card-wrapper{
                    width: 100%;
                    height: auto;
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
              

            .booking-card-title{
                font-size: 1.3rem;

                @media (max-width:767px) {
                    font-size: 1rem !important;
                    
                }
            }
            .booking-card-desc{
                font-size: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    @media (max-width:767px) {
        font-size: 13px !important;
        
    }
            }
            .booking-card-image{
                width: 100%;
                height: auto;
                img{
                    width: 100%;
                    height: 200px;
                border-radius: 10px;
            }
            }
        }
    }
    }
}  }