.pages-container {
    width: 100%;
    height: auto;


    .mytravellersinput1,
    .mytravellersinput2 {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: center;
        align-items: center;
        gap: .2rem;

        @media (max-width:1024px) {
            width: 100% !important;
            gap: 1rem;
            flex-direction: column !important;
        }
    }

    .mytravellersinput {
        display: flex;
        width: 55% !important;
        justify-content: space-between;
        align-items: center;
        gap: .6rem;
        border: 1px solid #eee;
        padding: .5rem;

        @media (max-width:1024px) {
            width: 100% !important;

        }
    }

    .myjourneydate {
        width: 45% !important;

        @media (max-width:1024px) {
            width: 100% !important;

        }
    }

    .single-line {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0.2rem 0;

        @media (max-width:767px) {

            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            margin: 0.2rem 0;
            flex-direction: column;
            gap: .2rem;


        }


    }


    .form-button {
        padding: .5rem;
        display: inline-block;
        color: #000;
        font-weight: 500;
        border-radius: 4px;
        text-transform: capitalize;
        // background-color:#ffe93d;
        background: linear-gradient(90deg, #f7971e 0%, #ffd200 100%);
        border: none;
        outline: none;
        cursor: pointer;
    }

    .banner {

        .image {
            width: 100%;
            height: 510px;

            @media (max-width:767px) {
                width: 100%;
                height: 300px;
                object-fit: fill;
            }

            img {
                width: 100%;
                height: 510px;
                object-fit: fill;

                @media (max-width:767px) {
                    width: 100%;
                    height: 300px;
                    object-fit: fill;
                }


            }
        }

    }

    .text-container {
        width: 100%;
        height: auto;



        .text-wrapper {
            // position: relative;
            // display: flex;
            // gap: 1rem;
            // margin: .5rem auto 4rem auto;
            // height: auto;
            // width: 95%;

            position: relative;
            display: flex;
            gap: 2rem;
            margin: .5rem auto;
            height: auto;
            width: 90%;
            justify-content: space-between;


            .left-content {
                width: 65%;
                height: auto;

                @media (max-width:767px) {
                    width: 100%;
                    height: auto;
                }

                .top {
                    margin: 1rem 0;
                    display: flex;
                    flex-direction: column;
                    gap: .5rem;


                    .package {
                        font-weight: 400;
                        font-size: 1rem;
                    }

                    .title {
                        font-size: 2rem;
                        font-weight: 600;

                        @media (max-width:767px) {
                            font-size: 1.5rem;
                            font-weight: 500;
                        }

                    }

                    .description {
                        .heading {
                            font-size: 1.5rem;
                            font-weight: 600;
                            margin-bottom: .5rem;

                            @media (max-width:767px) {
                                font-size: 1.4rem;
                                font-weight: 500;
                            }
                        }

                        .desc {
                            font-size: 1rem;
                            line-height: 1.5rem;
                            padding: .5rem 0;

                            @media (max-width:767px) {
                                font-size: 1rem;
                                font-weight: 400;
                                line-height: 1.2rem;
                            }
                        }

                        .bold {
                            font-size: 1.3rem;
                            font-weight: 500;

                        }

                        .read-more-less {
                            cursor: pointer;
                            color: #1677ff;
                        }
                    }

                    .facilities {
                        display: flex;
                        gap: 2rem;

                        @media (max-width:767px) {
                            gap: 1rem;
                            flex-wrap: wrap;
                        }

                        .perks {
                            display: flex;
                            align-items: center;
                            gap: .5rem;
                        }

                    }

                }

                .bottom {
                    padding: 1rem;
                    width: 100%;
                    min-height: 300px;
                    border: 2px solid #eee;
                    border-radius: 15px;
                    margin: 1rem 0;
                    box-shadow: 5px 5px 10px rgba(36, 39, 44, 0.07);

                    .heading {
                        font-size: 1.3rem;
                        font-weight: 400;
                    }

                    .desc {
                        font-size: 1rem;
                        line-height: 1.5rem;
                        padding: 0.5rem 0;
                        list-style-position: inside;

                        @media (max-width:767px) {
                            font-size: 1rem;
                            line-height: 1.2rem;

                        }

                    }

                    .bold {
                        font-size: 1.3rem;
                        font-weight: 500;

                    }

                    .read-more-less {
                        cursor: pointer;
                        color: #1677ff;
                    }


                    .date-blocks {
                        display: flex;
                        flex-direction: column;
                        gap: .5rem;

                        background-color: #f3f3f3;
                        padding: 1rem 1.5rem;
                        border-radius: 20px;

                        .batches {
                            padding: 1rem;
                            border-bottom: 2px solid #000;
                            display: flex;
                            justify-content: space-between;
                            align-items: flex-end;
                            gap: .5rem;

                            @media only screen and (max-width:767px) {
                                align-items: center;
                                border-bottom: 2px solid #000;
                                display: flex;
                                gap: 0.5rem;
                                justify-content: center;
                                padding: 1rem;
                                flex-direction: column;
                            }

                            .dates-start-end {
                                background-color: #FFD93D;
                                padding: 0.4rem;
                                border-radius: .5rem;
                            }

                            .left {
                                width: 100%;
                                height: auto;
                                display: flex;
                                flex-direction: column;
                                gap: .5rem;
                                font-size: 1rem;
                            }

                            .right {
                                width: 40%;
                                height: auto;
                                display: flex;
                                gap: 1rem;

                                @media (max-width:767px) {

                                    display: flex;
                                    gap: 1rem;
                                    height: auto;
                                    width: 100%;

                                }
                            }
                        }
                    }
                }

            }

            .right-content {
                position: relative;
                top: -140px;
                right: 0;
                width: 30%;
                height: auto;
                display: flex;
                flex-direction: column;
                gap: 0.3rem;

                @media (max-width:767px) {
                    position: relative;
                    bottom: 0;
                    right: 0;
                    top: 0;
                    right: 0;
                    width: 100%;
                    height: auto;
                }

                .form-container {
                    border: 3px solid #eee;
                    box-shadow: 5px 5px 10px rgba(36, 39, 44, 0.07);
                    background-color: #fff;
                    border-radius: 8px;
                    padding: 1rem;
                    width: 100%;
                    height: auto;
                    display: flex;
                    flex-direction: column;
                    gap: .4rem;



                    .title {
                        font-weight: 600;
                        font-size: 1.5rem;
                    }

                    .package {
                        font-weight: 400;
                        font-size: 1.3rem;
                    }

                    .packageId {
                        font-weight: 400;
                        font-size: 1rem;
                    }

                    .form {
                        display: flex;
                        flex-direction: column;
                        gap: 1rem;

                        input {
                            border: 1px solid #eee;
                            outline: none;
                            padding: .5rem;

                            &::placeholder {
                                font-size: .8rem;

                            }
                        }

                        .form-button {
                            padding: .5rem 1rem;
                            color: #000;
                            font-weight: 500;
                            border-radius: 4px;
                            text-transform: capitalize;
                            background: linear-gradient(90deg, #f7971e 0%, #ffd200 100%);
                            border: none;
                            outline: none;
                        }
                    }

                }
            }

        }
    }

    @media (max-width:767px) {
        .text-wrapper {
            position: relative;
            width: 95%;
            height: auto;
            display: flex;
            gap: 3rem;
            margin: 1rem auto;
            flex-direction: column;





            .left-content {
                width: 100%;
                height: auto;
                display: flex;
                flex-direction: column;


                .bottom {
                    padding: 1rem 0.5rem !important;
                    width: 100%;
                    min-height: 300px;
                    border: 2px solid #eee;
                    border-radius: 15px;
                    margin: 1rem 0;
                    box-shadow: 5px 5px 10px rgba(36, 39, 44, 0.07);
                }
            }


        }

    }
}

:where(.css-dev-only-do-not-override-mxhywb).ant-tabs-card>.ant-tabs-nav .ant-tabs-tab-active,
:where(.css-dev-only-do-not-override-mxhywb).ant-tabs-card>div>.ant-tabs-nav .ant-tabs-tab-active {
    color: #000;
    background: orange !important;
}

:where(.css-dev-only-do-not-override-mxhywb).ant-tabs-large>.ant-tabs-nav .ant-tabs-tab {
    padding: 16px 0;
    font-size: 20px;
    background-color: #FFD93D !important;
    color: #000;
}

:where(.css-dev-only-do-not-override-mxhywb).ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #1677ff;
    text-shadow: 0 0 0.25px currentcolor;
}

:where(.css-dev-only-do-not-override-mxhywb).ant-tabs .ant-tabs-tabpane {
    outline: none;
    padding: 1rem;
    list-style-position: inside;
}

:where(.css-dev-only-do-not-override-mxhywb).ant-collapse-large>.ant-collapse-item>.ant-collapse-content>.ant-collapse-content-box {
    padding: 16px 24px;
    list-style-position: inside;
}


:where(.css-dev-only-do-not-override-1uweeqc).ant-tabs .ant-tabs-tabpane .desc .list li {
    line-height: 1.5rem;
}