/*======================
    404 page
=======================*/

.page-container,
.page-wrapper {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
}

.page-wrapper .links {
    background-color: #FFD93D;
    padding: 1rem;
    text-decoration: none;
    font-size: 1rem;
    font-weight: 500;
    color: #000;
    border-radius: 5px;
}

.page_404 {
    padding: 40px 0;
    background: #fff;
    font-family: 'Arvo', serif;
}

.page_404 img {
    width: 100%;
}

.four_zero_four_bg {

    background-image: url(https://cdn.dribbble.com/users/722246/screenshots/3066818/404-page.gif);
    height: 650px;
    background-position: center;
    background-repeat: no-repeat;
}


.four_zero_four_bg h1 {
    font-size: 80px;
}

.four_zero_four_bg h3 {
    font-size: 80px;
}

.link_404 {
    color: #fff !important;
    padding: 10px 20px;
    background: #39ac31;
    margin: 20px 0;
    display: inline-block;
}

.contant_box_404 {
    margin-top: -50px;
}