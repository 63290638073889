.flight-form-container {
  // width: 100%;
  // height: auto;
  // margin: 0 auto;
  // padding: 2rem;
  // background-color: #fff;
  // border-radius: 4px;

  width: 100%;
    height: auto;
    margin: 0 auto;
    margin: 2rem auto;
    background-color: #fff;
    border-radius: 4px;
    width: 1180px;
}

.form-wrapper {
  width: 100%;
  height: auto;
  border: 4px solid #eee;
  border-radius: 10px;
  margin: 1rem auto;
  padding: 1rem;

  @media (max-width:767px){
   
      width: 90%;
  }
}

.form-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;

  @media (max-width:767px){
    
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 1rem;
   
  }
}

.form-col {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.form-title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 2rem;
    font-weight: 600;
    color: #00c2ff;
}

.form-label {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
}

input.form-input{
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0.5rem;
}

.form-submit-button {
  width: 100%;
  display: block;
  padding: 0.5rem 1rem;
  cursor: pointer;
  color: #000;
  font-weight: 500;
  border-radius: 4px;
  text-transform: capitalize;
  background: linear-gradient(90deg, #f7971e 0%, #ffd200 100%);
  border: none;
  outline: none;
}

@media (max-width: 768px) {
  .form-col {
    display: block;
    width: 100%;
  }
}

.form-group {
  position: relative;
}

.datalist-options {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  padding: 5px;
  max-height: 150px;
  overflow-y: auto;
}

.form-input:focus + .datalist-options {
  display: block;
}
.form-input-c{
  display: flex;
  gap:2rem;
  flex-direction: column;
}
.span-1{
  display: flex;
}
.span-1>.label-1{
  min-width: 120px;
}