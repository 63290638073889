.contact-page-container {
    width: 100%;
    height: auto;

    @media (max-width:767px) {
        .css-zr2rx9 {
            margin-top: 0 !important;
            margin-bottom: 0 !important;
            margin-left: 5px !important;
            margin-right: 5px !important;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-flex-direction: column;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-align-items: flexStart;
            -webkit-box-align: flexStart;
            -ms-flex-align: flexStart;
            align-items: flexStart;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            -webkit-justify-content: center;
            justify-content: center;
        }

        .css-zr2rx9 {
            margin-top: 0 !important;
            margin-bottom: 0 !important;
            margin-left: .5rem !important;
            margin-right: .5rem !important;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-flex-direction: column;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-align-items: flexStart;
            -webkit-box-align: flexStart;
            -ms-flex-align: flexStart;
            align-items: flexStart;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            -webkit-justify-content: center;
            justify-content: center;
            padding: 1rem 0.5rem;
        }

        .css-oekv3n-MuiGrid-root {
            box-sizing: border-box;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-flex-wrap: wrap;
            -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            width: 100%;
            -webkit-flex-direction: row;
            -ms-flex-direction: row;
            flex-direction: row;
            height: auto;
            padding: 0 !important;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            -webkit-justify-content: center;
            justify-content: center;
            -webkit-align-items: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
        }
    }

    .banner {
        position: relative;
        width: 100%;
        height: 500px;

        @media (max-width:767px) {
            height: 200px;

        }

        img {
            width: 100%;
            height: 100%;
            object-fit: fill;

        }
    }

    .contact-text {
        width: 100%;
        height: auto;

        .item {
            margin: .5rem 0;
            display: flex;
            gap: .5rem;
            font-size: 1rem;

            .icon {
                display: flex;
                justify-content: center;
                align-items: center;
            }

        }
    }



    .form-border {
        padding: 1rem;
        border: 5px solid #eee;
        border-radius: 10px;

        .heading {
            font-size: 1rem;
            font-weight: 500;
        }

        .form-button {
            padding: .5rem 1rem;
            color: #000;
            font-weight: 500;
            border-radius: 4px;
            text-transform: capitalize;
            background: linear-gradient(90deg, #f7971e 0%, #ffd200 100%);
            border: none;
            outline: none;
        }
    }

    .maplocation {
        width: 100%;
        height: 100%;
        padding: 1rem .5rem;

        iframe {
            width: 100%;
            height: 100%;
        }
    }
}