.section {
  width: 100%;
  height: auto;

  .section .banner .image img {
    width: 100%;
    height: 400px;
    object-fit: cover;
  }

  .destination-container {

    width: 1180px;
    height: 100%;
    margin: 2rem auto;
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    .section-title {
      color: #00c2ff;
      font-weight: 500;
      font-size: 2.5rem;
    }

    .card-container {
      width: 100%;
      height: auto;
      display: flex;
      // justify-content: center;
      justify-content: flex-start;
      align-items: center;
      gap: 1rem;
  

      .card-content {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        margin-bottom: 1rem;
        box-shadow: rgba(0, 0, 0, 0.19) -1px 4px 12px, rgba(0, 0, 0, 0.23) 1px 5px 10px;
        border-radius: 10px;
        .card-image {
          width: 100%;
          height: auto;

          .img {
            width: 280px;
            height: 300px;
            border-radius: 10px;
            margin: 0 auto;
          }

        }

        .card-overlay {
          position: absolute;
          bottom: -30px;
          width: 90%;
          height: auto;
          margin: 0 auto !important;
          padding: 1rem;
          display: flex;
          justify-content: center;

          flex-direction: column;

          .upper {
            border-top-right-radius: 10px;
            border-top-left-radius: 10px;
            padding: 0.5rem;
            background-color: rgba(0, 0, 0, 0.5);

            .card-title {
              font-size: 2rem;
              color: #fff;
            }
          }

          .bottom {
            border-bottom-right-radius: 10px;
            border-bottom-left-radius: 10px;
            padding: 0.8rem;

            background-color: #ffd93d;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07);
            color: #000;

            .card-package-details {
              display: flex;
              font-size: 1rem;
              justify-content: center;
              align-items: center;
              width: 100%;
              height: auto;
            }
          }
        }
      }
    }

    .slick-track{
      gap: 1rem !important;
      padding: 0 !important;
    }

    @media (max-width:767px) {
      .slick-track{
        gap: 0.3rem !important;
      }

    }

    .additional {
      position: relative;
      width: 100%;
      height: auto;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 5px;
      margin-top: 0.9rem;

      a,
      Link {
        color: #000;
        background-color: #ffd93d;
        padding: 0.5rem;
        border-radius: 10px;
      }

      .line {
        display: flex;
        gap: 5px;

        .text {
          font-size: 1.5rem;
        }

        .image {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 5px;
          background-color: #ffd93d;
        }
      }
    }
  }

  // tablet design
  @media (max-width: 1024px) {
    .destination-container {
      padding: 1rem;
      margin: 1rem auto;
      gap: 1rem;
      width: 768px;

      .section-title {
        font-size: 2rem;
      }

      .card-container {
        gap: 1rem;
        justify-content: center;

        .card-content {
          margin-bottom: 1rem;

          .card-image {
            width: 100%;
            height: auto;

            .img {
              width: 230px;
              height: 210px;
              border-radius: 10px;
            }
          }

          .card-overlay {
            position: absolute;
            bottom: -30px;
            padding: 0.9rem;

            .upper {
              .card-title {
                font-size: 1.5rem;
                color: #fff;
              }
            }

            .bottom {
              padding: 0.5rem;

              .card-package-details {
                font-size: 0.9rem !important;
              }
            }
          }
        }
      }
    }
  }

  // mobile design

  @media (max-width: 767px) {
    .destination-container {
      margin: 1rem auto;
      padding: .5rem 1rem;
      display: flex;
      flex-direction: column;
      gap: 2rem;
      // width: 370px;
      width: 100%;
      max-width: 370px;
      min-width: 300px;
      height: auto;

      .section-title {
        font-size: 1.2rem;
        display: flex;
        align-items: center;
        justify-content: space-around;
      }

      .card-container {
        gap: .5rem;
        margin: 0 auto;

        .card-content {
          margin: 0 auto;
          margin-bottom: 2rem;

          .card-image {
            .img {
              // width: 160px;
              // height: 220px;
              width: 160px;
              height: 170px;
            }


            @media (max-width:320px) {


              .img {
                // width: 160px;
                // height: 220px;
                width: 96%;
                min-width: 130px;
                max-width: 130px;
                height: 170px;
                border-radius: 10px;
              }
            }
          }

          .card-overlay {
            position: absolute;
            bottom: -23px;
            width: 96%;
            height: auto;
            margin: 0 auto !important;
            padding: 0.5rem;
            display: flex;
            justify-content: center;

            flex-direction: column;

            .upper {
              border-top-right-radius: 10px;
              border-top-left-radius: 10px;
              padding: 0.6rem;
              background-color: rgba(0, 0, 0, 0.5);

              .card-title {
                font-size: 1rem;
                color: #fff;
                text-align: center;
                font-weight: 600;
              }
            }

            .bottom {
              border-bottom-right-radius: 10px;
              border-bottom-left-radius: 10px;
              padding: 0;

              background-color: #ffd93d;
              box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07);

              .card-package-details {
                display: flex;
                font-size: 0.8rem !important;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                width: 100%;
                height: auto;
              }
            }
          }
        }
      }

      .additional {
        position: relative;
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;

        margin-top: 0.5rem;

        a,
        Link {
          color: #000;
        }

        .line {
          display: flex;
          gap: 5px;
          justify-content: center;
          align-items: center;

          .text {
            font-size: 1rem;
          }

          .image {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 5px;
            background-color: #ffd93d;
          }
        }
      }
    }
  }
}

/* -------------------------------------------------------------- */
/* SLICK REACT CAROUSEL */
/* -------------------------------------------------------------- */
.slick-next,
.slick-prev {
  top: 50%;

  width: 3rem;
  height: 3rem;
  transform: translate(0px, -50%);
  cursor: pointer;
  border: none;
  border-radius: 50%;
  // background-color: rgb(255, 255, 255);
  z-index: 1;
  // box-shadow: rgba(35, 35, 35, 0.5) 0px 0px 20px 5px;
  z-index: 99 !important;
}

// .slick-disabled{
//     display:none !important;
// }
.slick-prev {
  left: -40px;
}

.slick-next {
  right: -40px;
}

.slick-next:before {
  left: -25px;
  // content: '🡢';

  content: url(https://admin.aventuras.co.in/uploads/arrow_right_b4d68d463d.png) !important;
  font-size: 1.6rem;
  padding: 0.5rem;
  line-height: 1;
  opacity: 1;
  color: #000;
  border-radius: 50%;
  background-color: #ffd93d;
  border-radius: 50%;
}

.slick-prev:before {
  transform: rotate(180deg) !important;
  font-size: 1.6rem;
  padding: 0.5rem;
  line-height: 1;
  opacity: 1;
  color: #000;
  border-radius: 50%;
  background-color: #ffd93d;
  // content: '🡠';
  content: url(https://admin.aventuras.co.in/uploads/arrow_left_a382ca1233.png) !important;
}

@media (max-width: 1024px) {
  .slick-prev {
    left: -15px;
  }

  .slick-next {
    right: -15px;
  }

  .slick-next:before {
    font-size: 1.8rem !important;
  }

  .slick-prev:before {
    font-size: 1.8rem !important;
  }
}

@media (max-width: 767px) {
  .left{
    padding: 0.5rem;
  }
  .slick-prev {

    left: -25px;
   
  }

  .slick-next {
    right: -25px;
  }
  .line-text{
    width: 80%;
    height: 0.08px;
    background-color: gold;
  }
  .text_line-heading{
    white-space: nowrap;
    margin: 0 7px;
  }
}
.section-title-second{
  color: #00c2ff;
  font-weight: 500;
  font-size: 2.5rem;
  flex-direction: column;
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-top: 0.5rem;
}
@media (max-width: 767px) {
  .section-title-second{
    font-size: 1.5rem;
  justify-content: center;
  text-align: center;
  line-height: 2rem;
  }
}