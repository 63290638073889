.loader-container {
    width: 100%;
    height: 85vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFD93Dcc;
    flex-direction: column;

    img {
        width: 50px;
        height: 50px;
    }

    .message {
        display: flex;
        align-items: center;
        color: #000;

        .image {
            width: 100%;
            height: auto;

            img {
                width: 50px;
                height: 50px;
            }
        }

        .title {
            font-size: 2rem;
        }
    }

    .spinner-container {
        font-size: 1.5rem;


        .spinner {
            width: 64px;
            height: 64px;
            border: 8px solid;
            border-color: #3d5af1 transparent #3d5af1 transparent;
            border-radius: 50%;
            animation: spin-anim 5s linear infinite;
        }
    }

    .circular {
        width: 30px !important;
        height: 30px !important;
    }

    @keyframes spin-anim {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

    @media (max-width:767px) {
        .circular {
            width: 20px !important;
            height: 20px !important;
        }

        img {
            width: 50px;
            height: 50px;
        }

        .message {
            display: flex;
            flex-direction: column;
            align-items: center !important;
            color: #000;
            justify-content: center !important;

            .image {
                width: 100%;
                height: auto;

                img {
                    width: 50px;
                    height: 50px;
                }
            }

            .title {
                font-size: 1.5rem;
            }
        }

        .spinner-container {
            font-size: 1.2rem;

            .spinner {
                width: 34px !important;
                height: 34px !important;
                border: 8px solid;
                border-color: #3d5af1 transparent #3d5af1 transparent;
                border-radius: 50%;
                animation: spin-anim 5s linear infinite;
            }
        }
    }
}