.navBar {
    width: 100%;
    height: auto;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;

    box-shadow: 2px 2px 2px #eee;


    .showsmall {
        display: none !important;

        @media (max-width:767px) {

            display: flex !important;
            gap: 1rem !important;
            flex-direction: column;
            align-content: center;
            align-items: center;


        }
    }

    .showbig {
        display: block !important;

        @media (max-width:767px) {
            display: none !important;
        }
    }

    .navbar-container {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: space-around;
        margin: 0.5rem 2rem;
        z-index: 500;
        color: #000;
        max-height: 100px !important;
        max-width: 1226px;

        @media (max-width:767px) {
            padding: 0.1rem 1rem;
            margin: 0;
            flex-direction: row-reverse;
        }

        .navbar-left {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;

            .nav-logo {
                width: 100%;
                height: auto;

                .img {
                    width: 70px;
                    height: 70px;
                    object-fit: cover;

                    @media (max-width:1024px) {
                        width: 60px;
                        height: 60px;
                    }

                    @media (max-width:767px) {
                        width: 40px;
                        height: 40px;
                    }
                }

            }

            .navbar-heading {
                font-family: 'Outfit', sans-serif;
                font-weight: 600;
                color: #FFD93D;
                font-size: 1.5rem;

            }

        }

        .navbar-right {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1.1rem;

            width: 100%;

            @media (max-width:767px) {
                font-weight: 500;
                margin-top: 2rem;

            }

            .mymenu {
                background: transparent;
                color: #000;
                border: none;
                outline: none;
                font-size: 1rem;

                &:hover {
                    color: #000 !important;
                    border: none;
                    outline: none;
                }
            }

            .lists {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 1.5rem;
                list-style: none;
                padding: 0;
font-size: 1.1rem;
                position: static;
                visibility: visible;
                background: transparent;
                transform: initial !important;

                @media (max-width:767px) {
                    gap: 1rem !important;

                }

                li {
                    padding: .5rem 0;
                }

                a {
                    color: #000 !important;
                }

                .highlight {
                    background-color: #ffe93d;
                    border-radius: 5px;
                    padding: 5px 10px;
                }

                .contact-button {
                    border: 2px solid #000;
                    border-radius: 5px;
                    padding: 5px 10px;
                }
            }
        }

    }

    @media (max-width:1024px) {
        .navbar-right {
            font-size: 1.5rem !important;
        }
    }

    @media (max-width:767px) {
        .navbar {
            position: sticky;
            top: 0;
            left: 0;
            z-index: 500;
        }

        .navbar-right {
            font-size: 1.2rem !important;
        }

    }
}

.tooltip_div {
    width: 100%;
    text-align: end;
    display: flex;
    justify-content: end;
}

.closePop {
    background: gold;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    border-radius: 50%;
    padding: 0.5rem;
    cursor: pointer;
}