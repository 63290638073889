.section {
    width: 100%;
    height: auto;

    .footer-container {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: #292929;

        .top-container {
            width: 1180px;
            height: auto;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            gap: 3rem;
            padding: 3rem 0;
            margin: 0 auto;

            .left {
                width: 80%;
                height: auto;
                display: flex;
                gap: 1rem;
                flex-direction: column;

                .company-name {
                    color: #00C2FF;
                    font-size: 2.5rem;
                    font-weight: 700;
                }

                .contact {
                    display: flex;
                    flex-wrap: wrap;

                    .item {
                        padding: 0.5rem;

                        .link {
                            text-decoration: none;
                            color: #fff;
                            display: flex;
                            gap: 10px;
                            flex-wrap: wrap;
                        }
                    }
                }

                .payment {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;

                    .title {
                        font-size: 2.2rem;
                        color: #fff;
                        font-weight: 700;
                    }

                    .brand-images {
                        width: 100%;
                        height: auto;
                        display: flex;
                        gap: .4rem;

                        .image {
                            background-color: #fff;
                            padding: .3rem;
                        }

                        .img {
                            width: 60px;
                            height: 40px;
                        }
                    }
                }
            }

            .right {
                width: 100%;
                height: auto;
                display: flex;
                flex-direction: column;
                gap: 1rem;

                .form {
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;

                    .text {
                        color: #fff;
                        font-weight: 500;
                        font-size: 2rem;
                    }

                    .input {
                        height: auto;
                        width: 100%;
                        display: flex;

                        label {
                            background-color: #fff !important;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }

                        input[type="text"] {
                            border: none;
                            outline: none;
                            padding: 1rem;
                            width: 100%;
                            margin-right: .4rem;
                        }

                        button {
                            background-color: #00C2FF;
                            color: #000;
                            border: none;
                            cursor: pointer;
                            font-weight: 400;
                            font-size: 1rem;
                            padding: 1rem;


                        }
                    }


                }

                .footer-links {
                    width: 100%;
                    height: auto;
                    display: flex;
                    gap: 1.5rem;
                    font-size: 15px !important;

                    .left-col,
                    .right-col {
                        display: flex;
                        gap: 1.5rem;

                        .column {
                            display: flex;
                            flex-direction: column;
                            gap: 10px;


                            .heading {
                                color: #00C2FF;
                                font-size: 1rem;
                                border-bottom: 2px solid yellow;
                                padding-bottom: 2px;
                                font-weight: 400;
                            }

                            .lists {
                                padding: 0;

                                .list-item {

                                    list-style: none;

                                    a {
                                        color: #fff;
                                        line-height: 1.5rem;
                                        text-decoration: none;
                                        text-transform: uppercase;
                                        font-weight: 300;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .bottom-container {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: auto;
            padding: 1.5rem;
            background-color: #000;
            color: #fff;

            span {
                a {
                    text-decoration: none;
                    color: #fff;
                }
            }
        }
    }

    // tablet 
    @media (max-width:1024px) {
        .footer-container {
            position: relative;
            width: 100%;
            margin: 0 auto;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            .top-container {
                gap: 1rem;
                padding: 2rem 1rem;
                width: 768px;
                margin: 0 auto;

                .left {
                    width: 75%;
                    gap: 1rem;

                    .company-name {
                        font-size: 2.2rem;
                    }

                    .contact {
                        display: flex;
                        flex-wrap: wrap;

                        .item {
                            padding: 0.5rem;

                            .link {
                                text-decoration: none;
                                color: #fff;
                                display: flex;
                                gap: 10px;
                                flex-wrap: wrap;
                                align-items: center;
                                justify-content: flex-start;

                            }
                        }
                    }

                    .payment {
                        display: flex;
                        flex-direction: column;
                        gap: 10px;

                        .title {
                            font-size: 1.8rem;

                        }

                        .brand-images {
                            gap: .4rem;

                            .image {
                                background-color: #fff;
                                padding: .3rem;
                            }

                            .img {
                                width: 60px;
                                height: 40px;
                            }
                        }
                    }
                }

                .right {


                    .form {

                        .text {
                            font-size: 1.8rem;
                        }

                        .input {
                            height: auto;
                            width: 100%;

                            input[type="text"] {
                                border: none;
                                outline: none;
                                padding: 1rem;
                                width: 100%;
                                margin-right: .4rem;
                            }

                            button {
                                background-color: #00C2FF;
                                color: #000;
                                border: none;
                                cursor: pointer;
                                font-weight: 400;
                                font-size: 1rem;
                                padding: 1rem;

                            }
                        }
                    }

                    .footer-links {
                        flex-direction: column;
                        gap: 2rem;
                    }
                }
            }
        }
    }

    // mobile
    @media (max-width:767px) {

        .footer-container {
            position: relative;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            .top-container {
                width: 100%;
                height: auto;
                background-color: #292929;
                display: flex;
                justify-content: center;
                align-items: flex-start;
                gap: 1rem;
                padding: 1rem;
                flex-direction: column;



                .left {
                    width: 94%;
                    height: auto;
                    display: flex;
                    gap: 1rem;
                    flex-direction: column;

                    .company-name {
                        font-size: 1.3rem;
                    }

                    .contact {
                        display: flex;
                        font-size: .8rem !important;
                        flex-wrap: wrap;
                    }

                    .payment {
                        display: flex;
                        flex-direction: column;
                        gap: 10px;

                        .title {
                            font-size: 1.2rem;
                            color: #fff;
                            font-weight: 700;
                        }

                        .brand-images {
                            width: 100%;
                            height: auto;
                            display: flex;
                            gap: .4rem;

                            .image {
                                background-color: #fff;
                                padding: .3rem;
                            }

                            .img {
                                width: 50px;
                                height: 30px;
                            }
                        }
                    }
                }

                .right {
                    width: 100%;
                    height: auto;
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;

                    .form {
                        display: flex;
                        flex-direction: column;
                        gap: 1rem;

                        .text {
                            color: #fff;
                            font-weight: 500;
                            font-size: 1.6rem;
                        }

                        .input {
                            height: auto;
                            width: 100%;

                            input[type="text"] {
                                border: none;
                                outline: none;
                                padding: .5rem;
                                width: 100%;
                                margin-right: .4rem;
                                font-size: 1.1rem;
                            }

                            button {
                                background-color: #00C2FF;
                                color: #000;
                                border: none;
                                cursor: pointer;
                                font-weight: 400;
                                font-size: 1rem;
                                padding: 1rem;


                            }
                        }


                    }

                    .footer-links {
                        width: 100%;
                        height: auto;
                        display: flex;
                        gap: 2rem;
                        flex-direction: column;
                        font-size: .8rem !important;

                        .left-col {
                            display: flex;
                            gap: 2rem;

                        }

                        .right-col {
                            display: flex;
                            gap: 1rem;

                        }
                    }




                }

            }

            .bottom-container {
                .text {
                    font-size: .8rem;
                }
            }
        }
    }
}

.bottomMenu {

    .item-container {
        position: sticky;
        bottom: 0;
        left: 0;
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #ffe93d;

        .item {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap: 4px;
            width: 100%;
            height: auto;
            background-color: #ffe93d;
            color: #000;
        }
    }
}