.section {
    width: 100%;
    height: auto;

    .banner {

        .image {
            width: 100%;
            height: auto;

            img {
                width: 100%;
                height: 400px;
                object-fit: cover;

                @media (max-width:767px) {
                    width: 100%;
                    height: 250px;
                    // object-fit: cover;
                    object-fit: fill;

                }
            }
        }

    }

    .theme-container {
        width: 1180px;
        height: 100%;
        margin: 2rem auto;
        padding: 1rem 0;
        display: flex;
        flex-direction: column;
        gap: 2rem;

        .section-title {
            color: #00C2FF;
            font-weight: 500;
            font-size: 2.5rem;

        }

        .card-container {
            width: 100%;
            height: auto;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 1rem;
            border-radius: 0;
            flex-wrap: wrap;

            .card-content {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 10px;
                box-shadow: rgba(0, 0, 0, 0.19) -1px 4px 10px, rgba(0, 0, 0, 0.23) 1px 5px 10px;

                .card-image {
                    width: 100%;
                    height: auto;
                    border-radius: 0;

                    .img {
                        width: 280px;
                        height: 200px;
                        border-radius: 10px;
                        margin: 0 auto;
                    }
                }

                .card-overlay {
                    position: absolute;

                    width: 100%;
                    height: auto;



                    .upper {

                        width: 75%;
                        height: auto;
                        margin: 0 auto;
                        padding: 1rem;
                        text-align: center;
                        background-color: rgba(0, 0, 0, 0.4);
                        border-radius: 5px;


                        .card-title {
                            color: #fff;
                            font-style: normal;
                            font-weight: 500;
                            font-size: 2rem;
                            line-height: 30px;
                        }
                    }
                }
            }
        }

        .additional {
            position: relative;
            width: 100%;
            height: auto;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin-top: 0.5rem;

            a,
            Link {

                color: #000;
                background-color: #ffd93d;
                padding: 0.5rem;
                border-radius: 10px;
            }

            .line {
                display: flex;
                gap: 5px;
                justify-content: center;
                align-items: center;

                .text {
                    font-size: 1.5rem;
                }

                .image {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 5px;
                    background-color: #FFD93D;
                }
            }


        }
    }

    // tablet responsive design
    @media (max-width:1024px) {
        .theme-container {


            margin: 1rem auto;
            padding: 1rem;
            gap: 2rem;
            width: 768px;

            .section-title {
                font-size: 2rem;

            }

            .card-container {
                .card-content {
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 0;

                    .card-image {
                        width: 100%;
                        height: auto;
                        border-radius: 0;

                        .img {
                            width: 232px;
                            height: 180px;
                            border-radius: 0;
                        }
                    }

                    .card-overlay {
                        position: absolute;
                        width: 100%;
                        height: auto;

                        .upper {

                            width: 90%;
                            height: auto;
                            margin: 0 auto;
                            padding: 1rem 2rem;
                            background-color: rgba(0, 0, 0, 0.4);

                            .card-title {
                                color: #fff;
                                font-style: normal;
                                font-weight: 600;
                                font-size: 1.8rem;
                                line-height: 30px;
                            }
                        }
                    }
                }
            }

            .additional {
                position: relative;
                width: 100%;
                height: auto;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                gap: 5px;
                margin-top: 0;

                .text {
                    font-size: 1.5rem;
                }

                .image {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 5px;
                    background-color: #FFD93D;
                }


            }
        }
    }


    // mobile responsive design
    @media (max-width:767px) {
        .theme-container {
            width: 100%;
            max-width: 370px;
            min-width: 300px;
            margin: 0 auto;
            padding: 1rem;
            display: flex;
            flex-direction: column;
            gap: 2rem;

            .section-title {
                font-size: 1.2rem;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .card-container {
                width: 100%;
                height: auto;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: .5rem;

                .card-content {
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 10px;

                    .card-image {
                        width: 100%;
                        height: auto;
                        border-radius: 0;

                        @media(max-width:767px) {}

                        .img {
                            width: 340px;
                            height: 230px;
                            border-radius: 10px;
                        }

                        @media (max-width:1024px) {

                            .img {
                                width: 230px;
                                height: 180px;

                            }
                        }

                        @media (max-width:767px) {
                            .img {
                                width: 160px;
                                height: 170px;
                                border-radius: 10px;
                            }
                        }

                        @media (max-width:320px) {
                            .img {
                                width: 130px;
                                height: 170px;
                                border-radius: 10px;
                            }
                        }
                    }

                    .card-overlay {
                        position: absolute;

                        width: 100%;
                        height: auto;


                        .upper {

                            width: 85%;
                            height: auto;
                            margin: 0 auto;
                            padding: 2rem;
                            background-color: rgba(0, 0, 0, 0.4);

                            .card-title {
                                color: #fff;
                                font-style: normal;
                                font-weight: 600;
                                font-size: 1.2rem;
                                line-height: 25px;
                            }
                        }

                        @media (max-width:1024px) {

                            .upper {
                                width: 85%;
                                height: auto;
                                margin: 0 auto;
                                padding: 1rem;
                                background-color: rgba(0, 0, 0, 0.4);
                            }

                        }

                        @media (max-width:767px) {
                            .upper {
                                width: 85%;
                                height: auto;
                                margin: 0 auto;
                                padding: .6rem;
                                background-color: rgba(0, 0, 0, 0.4);
                            }
                        }
                    }
                }
            }

            .additional {
                position: relative;
                width: 100%;
                height: auto;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 5px;

                .text {
                    font-size: 1rem !important;
                }

                .image {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 5px;
                    background-color: #FFD93D;
                }
            }
        }
    }
}

@media (max-width:767px){
    .line-text{
        width: 35%;
        height: 0.08px;
        background-color: gold;
      }
      .text_line-heading{
        white-space: nowrap;
        margin: 0 10px;
      }
}