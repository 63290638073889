.profile-container {
  
   
        // min-height: 100% !important;
        min-height: 35vh !important;
        height: auto;
    width: 100%;
    max-width: 1180px;
    margin: 1rem auto;
    display: flex;
    flex-direction: row !important;
    @media (max-width:767px) {
        display: flex;
        flex-direction: column !important;
        
    }

    .dashboard-container{
        width: 100%;
        height: auto;
        padding: 1rem;
        // overflow: hidden;
    
        display: flex;
        flex-direction: column;
        gap:1rem;

        @media (max-width:767px) {
            padding: 0.5rem !important;
            
        }
        .activities-heading{
            color: #00ccff;

            @media (max-width:767px) {
                font-size: 1.1rem !important;
                
            }
        }
        .cards-wrapper{
            width: 100%;
            height: auto;
            padding: 0;
        }

        .tabs-container{
            width: 100%;
            height: auto;
            padding: 0;

            .css-19kzrtu {
                padding: 0 !important;
            }
        }
    }
    .admin-title {
        font-size: 1.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        padding: 1rem;
    }

    .profile-tabs-sidebar {
        min-width: 20%;
        background-color: #ffe93d;
            // background-color: #eee;
            border-radius: 10px;
            margin: 0.5rem;
//  .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
//         color: #fff !important;
//         background-color:#00ccff !important ;
     
//     }  
            
    // .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
    //     color: #00C2FF;
    // }  
    }
  


   
}
// .css-10d9dml-MuiTabs-indicator {
//     width: 4px !important;
//     left: 0 !important;
//     background-color: #292929 !important;
// }
@media(max-width:767px){
    .css-1h9z7r5-MuiButtonBase-root-MuiTab-root{
        min-width: 100% !important;
    }
    .css-1q2h7u5.Mui-selected{
        max-width: 100% !important;
    }
}
.no-booking-heading{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 2.5rem;
    font-weight: 600;
    align-items: center;
    font-size: 1.2rem;
}


.css-1q2h7u5.Mui-selected{
    background-color: rgb(51, 137, 223) !important;
    color: #fff !important;
   
}
.css-7ozjwe {
    background-color: #292929 !important;
    left: 0 !important;
}