.disclaimer-page-container {
    height: auto;
    width: 100%;
    max-width: 1180px;
    margin: 0 auto;

    .disclaimer-page-wrapper {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        padding: 2rem 1rem;
        line-height: 1.5rem;

        .disclaimer-text-container {
            width: 100%;
            height: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap: 1rem;




            .heading {
                font-size: 2rem;
                font-weight: 600;
                color: #00c2ff;

                @media (max-width:767px) {
                    font-size: 1.7rem;

                }
            }

            .desc-container {
                width: 100%;
                height: auto;
                display: flex;
                flex-direction: column;
                gap: 1rem;
                flex-direction: column;

                .desc {
                    font-size: 1.1rem;
                }

            }
        }
    }
}