.flight-page-container {
    width: 100%;
    height: auto;
    display: flex;
    // justify-content: center;
    align-items: center;
    // padding: 3rem;

    .rbc-event,
    .rbc-day-slot .rbc-background-event {
        border: none;
        box-sizing: border-box;
        box-shadow: none;
        margin: 0;
        padding: 2px 5px;
        // background-color: #3174ad;
        background-color: green;
        border-radius: 5px;
        color: #fff;
        cursor: pointer;
        width: 100%;
        text-align: left;
    }
}

.flight-card {
    background-color: #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 16px;
  
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12px 16px;
      cursor: pointer;
  
      h3 {
        font-size: 18px;
        font-weight: bold;
        margin: 0;
      }
  
      p {
        font-size: 16px;
        margin: 0;
      }
  
      .arrow {
        width: 12px;
        height: 12px;
        border-left: 2px solid #333;
        border-bottom: 2px solid #333;
        transform: rotate(-45deg);
        transition: transform 0.3s ease-in-out;
  
        &.up {
          transform: rotate(45deg);
        }
      }
    }
  
    .content {
      padding: 12px 16px;
      font-size: 14px;
      line-height: 1.5;
      color: #333;
    }
  }
  