.page-container {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem;

  .rbc-event,
  .rbc-day-slot .rbc-background-event {
    border: none;
    box-sizing: border-box;
    box-shadow: none;
    margin: 0;
    padding: 2px 5px;
    // background-color: #3174ad;
    background-color: green;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    width: 100%;
    text-align: left;
  }
}

.section {
  width: 100%;
  height: auto;

  .banner {
    .image {
      width: 100%;
      height: auto;

      img {
        width: 100%;
        height: 400px;
        object-fit: fill;

        @media (max-width: 767px) {
          width: 100%;
          height: 250px;
          // object-fit: cover;
          object-fit: fill;
        }
      }
    }
  }

  .group-package-container {
    // width: 100%;
    // height: 100%;
    // margin: 2rem 0rem;
    // padding: 1rem 3rem;
    // display: flex;
    // flex-direction: column;
    // gap: 2rem;
    width: 1180px;
    height: 100%;
    margin: 2rem auto;
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    .section-title {
      color: #00c2ff;

      font-weight: 500;
      font-size: 2.5rem;

      display: flex;
    }

    .card-container {
      width: 100%;
      height: auto;
      display: flex;
      // justify-content: center;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      // flex-wrap: wrap;

      a,
      Link {
        color: #000 !important;
        width: 300px;
        height: 300px; 
           box-shadow: 0 0 0 #00000030, 0 8px 6px #0000003b;
           border-radius: 10px;
           @media(max-width:767px){
            width: 46%;
height: 200px;
            min-width: 130px;
            margin-bottom: 1rem;
           }

      }

      .card-content {
        position: relative;
        display: block;

        // &:hover {
        //     transition: all .5s ease-in-out;
        //     transform: scale(1.02);
        // }

        .card-image {
          width: 100%;
          height: auto;

          .img {
            width: 100%;
            height: 180px;
            border-radius: 10px 10px 0px 0px;
            margin: 0 auto;
          }
        }

        .card-overlay {
          width: 100%;
          height: auto;

          margin: 0 auto !important;

          .upper {
            width: 100%;
            height: auto;
            margin: 0 auto;
            padding: 0.5rem 0.5rem;
            background-color: #fff;
            border-radius: 0px 0px 10px 10px;

            .card-title {
              // color: #fff;
              font-style: normal;
              font-weight: 600;
              font-size: 1.3rem;
              line-height: 30px;
              height: 53px;
            }

            .card-package {
              color: #000;
              background-color: #fff;
              border-radius: 15px;
              width: 100%;
              text-align: center;
              height: auto;
              padding: 0.3rem;
              border: 1px solid #ffe93d;
            }
          }

          .card-package-id {
            background-color: #ffd93d;
            width: 70%;
            height: auto;
            margin: 0 auto;
            padding: 0.8rem;
            text-align: center;
            border-bottom-right-radius: 10px;
            border-bottom-left-radius: 10px;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07);
            font-size: 0.8rem;
            font-weight: 500;
          }
        }

        .middle {
          transition: 0.5s ease;
          opacity: 0;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
          text-align: center;
        }

        &:hover .card-image,
        &:hover .card-overlay {
          opacity: 0.3;
          transition: 1s ease;
        }

        &:hover .middle {
          opacity: 1;
        }

        .text {
          .form-button {
            padding: 0.5rem;
            color: #000;
            font-weight: 500;
            border-radius: 4px;
            text-transform: capitalize;
            background-color: #ffe93d;
            // background: linear-gradient(90deg, #f7971e 0%, #ffd200 100%);
            border: none;
            outline: none;
          }
        }
      }
    }

    .additional {
      position: relative;
      width: 100%;
      height: auto;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      margin-top: 0.5rem;

      a,
      Link {
        color: #000;
      }

      .line {
        display: flex;
        gap: 5px;

        .text {
          font-size: 1.5rem;
        }

        .image {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 5px;
          background-color: #ffd93d;
        }
      }
    }
  }

  // tablet design
  @media (max-width: 1024px) {
    .group-package-container {
      width: 768px;
      padding: 1rem;
      margin: 1rem auto;

      .section-title {
        font-size: 2rem;
      }

      .card-container {
        .card-content {
          .card-image {
            .img {
              margin: 0 auto;
            }
          }

          .card-overlay {
     
            width: 100%;
            height: auto;

            margin: 0 auto !important;

            .upper {
              width: 85%;
              padding: 0.5rem;

              .card-title {
                font-size: 1.2rem;
                height: 55px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2; /* Change this line if you want. In this case it trimmed the text to 3 lines. */
                overflow: hidden;
                line-height: normal;
              }

              .card-package {
                width: 100%;
                text-align: center;
                height: auto;
                padding: 0.3rem;
                font-size: 0.8rem;
              }
            }

            .card-package-id {
              font-size: 0.8rem;
              width: 70%;
              text-align: center;
            }
          }
        }
      }

      .additional {
        position: relative;
        width: 100%;
        height: auto;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 5px;
        margin-top: 0.5rem;

        .text {
          font-size: 1.5rem;
        }

        .image {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 5px;
          background-color: #ffd93d;
        }
      }
    }
  }

  // mobile design
  @media (max-width: 767px) {
    .group-package-container {
      // width: 370px;
      width: 100%;
      max-width: 370px;
      min-width: 300px;
      height: auto;
      margin: 0 auto;
      padding: .5rem;
      gap: .5rem;

      .section-title {
        font-size: 1.5rem;
        justify-content: center;
        margin: 1rem 0;
      }

      .card-container {
        margin: 0.5rem 0;
        // gap: 3rem;

        .card-content {
          .card-image {
            .img {
              width: 100%;
              min-width: 140px;
              max-width: 163px;
              height: 110px;
              border-radius: 10px 10px 0px 0px;
            }

@media (max-width:320px){


            .img {
              // width: 160px;
              // height: 220px;
              width: 100%;
          
              height: 100px;
              border-radius: 10px 10px 0px 0px;
            }
          }
          }

          .card-overlay {
            position: relative;
      bottom: 0;
            width: 100%;
            height: auto;

            .upper {
              width: 100%;
              height: auto;
              margin: 0 auto;
              padding: 0.5rem;
              background-color: #ffff;
              border-radius: 10px;

      .card-title {
                font-style: normal;
                font-weight: 600;
                font-size: 0.8rem;
                line-height: normal;
                height: 40px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2; /* Change this line if you want. In this case it trimmed the text to 3 lines. */
                overflow: hidden;
            }

              .card-package {
                color: #000;
                background-color: #fff;
                border-radius: 15px;
                width: 100%;
                text-align: center;
                height: auto;
                padding: 0.3rem;
                font-size: 0.6rem;
                font-weight: 500;
              }
            }

            .card-package-id {
              background-color: #ffd93d;
              width: 80%;
              height: auto;
              margin: 0 auto;
              padding: 0.6rem 0.2rem;
              text-align: center;
              border-bottom-right-radius: 10px;
              border-bottom-left-radius: 10px;
              font-size: 0.5rem;
              font-weight: 600;
            }
          }
        }
      }

      .additional {
        margin-top: 2rem;

        .text {
          font-size: 1rem;
        }
      }
    }
  }
}

/* -------------------------------------------------------------- */
/* SLICK REACT CAROUSEL */
/* -------------------------------------------------------------- */
.slick-next,
.slick-prev {
  top: 50%;

  width: 3rem;
  height: 3rem;
  transform: translate(0px, -50%);
  cursor: pointer;
  border: none;
  border-radius: 50%;
  // background-color: rgb(255, 255, 255);
  z-index: 1;
  // box-shadow: rgba(35, 35, 35, 0.5) 0px 0px 20px 5px;
  z-index: 99 !important;
}

// .slick-disabled{
//     display:none !important;
// }
.slick-prev {
  left: -45px;
}

.slick-next {
  right: -45px;
}


@media (max-width: 1024px) {
  .slick-prev {
    left: -15px;
  }

  .slick-next {
    right: -15px;
  }

  .slick-next:before {
    font-size: 1.8rem !important;
  }

  .slick-prev:before {
    font-size: 1.8rem !important;
  }
}

@media (max-width: 767px) {
  .slick-prev {
    left: -16px;
  }

  .slick-next {
    right: -18px;
  }
}

.slick-next:before {
  left: -25px;
  // content: '🡢' !important;
  content: url(https://admin.aventuras.co.in/uploads/32213_removebg_preview_2c190204bb.png) !important;

  font-size: 1.6rem;
  padding: 0.8rem;
  line-height: 1;
  opacity: 1;
  color: #000;
  border-radius: 50%;
  background-color: #ffd93d;
  border-radius: 50%;
  width: 100%;
height: 100%;

}

// content: '🡠';
.slick-prev:before {
  // content: '🡠' !important;
  content: url(https://admin.aventuras.co.in/uploads/left_arrow_8ea5b4c7d2.png) !important;
  // transform: rotate(90deg);
  font-size: 1.6rem;
  padding: 0.8rem;
  line-height: 1;
  opacity: 1;
  color: #000;
  border-radius: 50%;
  background-color: #ffd93d;
  width: 100%;
  height: 100%;


}
@media (max-width:767px) {

  .slick-prev {
    left: -8px !important;
}
  .slick-next:before {
    left: -25px;
    // content: '🡢' !important;
    content: url(https://admin.aventuras.co.in/uploads/32213_removebg_preview_2c190204bb.png) !important;
  
    font-size: 1.6rem;
    padding: 0.3rem !important;
    line-height: 1;
    opacity: 1;
    color: #000;
    border-radius: 50%;
    background-color: #ffd93d;
    border-radius: 50%;
  }
  .slick-prev:before {
    // content: '🡠' !important;
    content: url(https://admin.aventuras.co.in/uploads/left_arrow_8ea5b4c7d2.png) !important;
    // transform: rotate(90deg);
    font-size: 1.6rem;
    padding: 0.3rem;
    line-height: 1;
    opacity: 1;
    color: #000;
    border-radius: 50%;

    background-color: #ffd93d;
  }
}
