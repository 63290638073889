.section {
  width: 100%;
  height: auto;

  .banner {
    .image {
      width: 100%;
      height: auto;

      img {
        width: 100%;
        height: 400px;
        object-fit: cover;

        @media (max-width: 767px) {
          width: 100%;
          height: 250px;
          // object-fit: cover;
          object-fit: cover;
        }
      }
    }
  }

  .packages-container {
    width: 1180px;
    height: 100%;
    margin: 2rem auto;
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
    gap: 2rem;


    .section-title {
      color: #00c2ff;

      font-weight: 500;
      font-size: 2.5rem;

      display: flex;
    }

    .card-container {
      width: 100%;
      height: auto;
      display: flex;
      // justify-content: center;
      justify-content: flex-start;
      align-items: center;
      gap: 1rem;
      flex-wrap: wrap;

      a,
      Link {
        color: #000 !important;
        max-width: 280px;
        margin-bottom: 1rem;
        @media (max-width: 1024px) {
          width: 230px;
                  }
        @media (max-width: 767px) {
width: 46%;
min-width: 133px;
max-width: 160px;

        }
      }

      .card-content {
        position: relative;
        display: block;
        margin: 0.5rem 0;
        box-shadow: rgba(0, 0, 0, 0.19) 0px 0px 0px, rgba(0, 0, 0, 0.23) 0px 8px 6px;
        border-radius: 10px;
height: 300px;


@media (max-width: 767px) {
  height: 195px;
          }
        
  

        .card-image {
          width: 100%;
          height: auto;

          .img {
            width: 280px;
            height: 180px;
            border-radius: 10px;
            margin: 0 auto;
            border-radius: 10px 10px 0px 0px;
            
          }
        }

        .card-overlay {
          // position: absolute;
          // bottom: -10px;
          width: 100%;
          height: auto;

          margin: 0 auto !important;

          .upper {
            width: 100%;
            height: auto;
            margin: 0 auto;
            padding: 0.5rem 0.8rem;
            background-color: #fff;
            border-radius: 0px 0px 10px 10px;

            .card-title {
              color: #000;
              font-style: normal;
              font-weight: 600;
              font-size: 1.1rem;
              line-height: 25px;
              height: 53px;
            }

            .card-package {
              color: #000;
              background-color: #fff;
              border-radius: 15px;
              width: 100%;
              text-align: center;
              height: auto;
              padding: 0.3rem;
              border: 1px solid gold;
              font-weight: 550;
              margin: 0.2rem 0;
            }
          }

          .card-package-id {
            background-color: #ffd93d;
            width: 70%;
            height: auto;
            margin: 0 auto;
            padding: 0.8rem;
            text-align: center;
            border-bottom-right-radius: 10px;
            border-bottom-left-radius: 10px;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07);
            font-size: 0.8rem;
            font-weight: 500;
            position: absolute;
            left: 16%;
            top: 95%;
          }
        }

        .middle {
          transition: 0.5s ease;
          opacity: 0;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
          text-align: center;
        }

        &:hover .card-image,
        &:hover .card-overlay {
          opacity: 0.5;
          transition: 1s ease;
        }

        &:hover .middle {
          opacity: 1;
        }

        .text {
          .form-button {
            padding: 0.5rem;
            color: #000;
            font-weight: 500;
            border-radius: 4px;
            text-transform: capitalize;
            background-color: #ffe93d;
            // background: linear-gradient(90deg, #f7971e 0%, #ffd200 100%);
            border: none;
            outline: none;
          }
        }
      }
    }

    .additional {
      position: relative;
      width: 100%;
      height: auto;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      margin-top: 0.5rem;

      a,
      Link {
        color: #000;
        background-color: #ffd93d;
        padding: 0.5rem;
        border-radius: 10px;
      }

      .line {
        display: flex;
        gap: 5px;
        justify-content: center;
        align-items: center;

        .text {
          font-size: 1.5rem;
        }

        .image {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 5px;
          background-color: #ffd93d;
        }
      }
    }
  }

  // tablet design
  @media (max-width: 1024px) {
    .packages-container {
      width: 768px;
      padding: 1rem;
      margin: 1rem auto;

      .section-title {
        font-size: 2rem;
      }

      .card-container {
        .card-content {
          .card-image {
            .img {
              width: 100%;
              margin: 0 auto;
            }
          }

          .card-overlay {
            width: 100%;
            height: auto;

            margin: 0 auto !important;

            .upper {
              width: 85%;
              padding: 0.8rem;

              .card-title {
                font-size: 0.9rem;
                line-height: 18px;

              }

              .card-package {
                text-align: center;
                height: auto;
                padding: 0.3rem;
                font-size: 0.8rem;
              }
            }

            .card-package-id {
              font-size: 0.8rem;
              width: 75%;
              padding: 0.5rem;
              text-align: center;
              left: 13%;
            }
          }
        }
      }

      .additional {
        position: relative;
        width: 100%;
        height: auto;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 5px;
        margin-top: 0rem;

        .text {
          font-size: 1.5rem;
        }

        .image {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 5px;
          background-color: #ffd93d;
        }
      }
    }
  }

  // mobile design
  @media (max-width: 767px) {
    .packages-container {
      // width: 370px;
      width: 100%;
      max-width: 370px;
      min-width: 300px;
      height: auto;
      margin: 2rem auto;
      // padding: 1rem;
      // gap: .8rem;
      padding: 0.5rem;
      gap: 1rem;



      .section-title {
        font-size: 1.2rem;
        justify-content: space-around;
        align-items: center;
      }

      .card-container {
        margin: 0.5rem 0;
        justify-content: space-around;

        // gap: 3rem;
        .card-content {
          .card-image {
            .img {
              // width: 160px;
              // height: 220px;
              width: 100%;
              // min-width: 140px;
              // max-width: 160px;
              height: 110px;
              border-radius: 10px 10px 0px 0px;
            }

      
          }

          .card-overlay {
            position: relative;
            bottom: 0;
            width: 100%;
            height: auto;

            .upper {
              width: 100%;
              margin: 0 auto;
              padding: 0.5rem;
              background-color: #fff;
              border-radius: 10px;

              .card-title {
                color: #000;
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                height: 36px;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 2; /* Change this line if you want. In this case it trimmed the text to 3 lines. */
                  overflow: hidden;
                
        
              }

              .card-package {
                color: #000;
                background-color: #fff;
                border-radius: 15px;
                width: 100%;
                text-align: center;
                height: auto;
                font-size: 0.6rem;
                font-weight: 500;
                border: 1px solid #ffe93d;
                margin: 7px 0;
              }
            }

            .card-package-id {
              background-color: #ffd93d;
              // width: 65%;
              width: 80%;
              height: auto;
              margin: 0 auto;
              padding: 0.5rem 0.2rem;
              text-align: center;
              border-bottom-right-radius: 10px;
              border-bottom-left-radius: 10px;
              font-size: 0.6rem;
              font-weight: 500;
              top: 90%;
              left: 10%;
            }
          }

          .middle {
            transition: 0.5s ease;
            opacity: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            text-align: center;
            width: 70%;
            font-size: .8rem;
            padding: 0.5rem;
          }
        }
      }

      .additional {
        margin-top: 1rem;
        display: flex;
        justify-content: center;
        font-size: 1rem;
        font-weight: 400;


        .text {
          font-size: 1rem !important;
        }
      }
    }
  }
}

/* -------------------------------------------------------------- */
/* SLICK REACT CAROUSEL */
/* -------------------------------------------------------------- */
.slick-next,
.slick-prev {
  top: 50%;

  width: 3rem;
  height: 3rem;
  transform: translate(0px, -50%);
  cursor: pointer;
  border: none;
  border-radius: 50%;
  z-index: 99 !important;
}

// .slick-disabled{
//     display:none !important;
// }
.slick-prev {
  left: -50px;
}

.slick-next {
  right: -50px;
}

@media (max-width: 1024px) {
  .slick-prev {
    left: -15px;
  }

  .slick-next {
    right: -15px;
  }

  .slick-next:before {
    font-size: 1.8rem !important;
  }

  .slick-prev:before {
    font-size: 1.8rem !important;
  }
}

@media (max-width: 767px) {
  .slick-prev {
    left: -25px;
  }

  .slick-next {
    right: -25px;
  }
}

.slick-next:before {
  left: -25px;
  // content: '🡢' !important;
  content: url(https://admin.aventuras.co.in/uploads/righticon_0313ec224f.jpg) !important;
  font-size: 1.6rem;
  padding: 0.5rem;
  line-height: 1;
  opacity: 1;
  color: #000;
  border-radius: 50%;
  background-color: #ffd93d;
  border-radius: 50%;
}

// content: '🡠';
.slick-prev:before {
  // content: '🡠' !important;
  content: url(https://admin.aventuras.co.in/uploads/left_icon_4ec7a8d0c8.jpg) !important;

  font-size: 1.6rem;
  padding: 0.5rem;
  line-height: 1;
  opacity: 1;
  color: #000;
  border-radius: 50%;
  background-color: #ffd93d;
}
.price_tag_Container{
  // background: linear-gradient(90deg, rgb(247, 151, 30) 0%, rgb(255, 210, 0) 100%) !important;
  background: #ffd93d  !important;
  width: fit-content;
  padding: 0.3rem;
  position: absolute;
  top: 5%;
  font-size: 0.7rem;
  font-weight: 600;
  display: flex;
  gap: 0.2rem;
  align-items: center;
  border-radius: 0px 4px 4px 0px;
}
.price_tag_Container img{
  width: 15px;
  height: 15px;
  rotate: 270deg;
}
@media(max-width:767px){
  .price_tag_Container {
    padding: 0.2rem 0.15rem;
    top: 5%;
    left: 0.54%;
}
.price_tag_Container img {
  width: 13px;
  height: auto;
}
.line-text{
  width: 20%;
  height: 0.08px;
  background-color: gold;
}

.text_line-heading{
  white-space: nowrap;
}
}

