
.banner_overlay{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #292929;
    font-size: 2.5rem;
    font-weight: 600;
    margin: 1rem 0;
    gap: 0.5rem;
    line-height: 5rem;
}
.border_text{
    margin-top: 3px;
    border-bottom: 3px solid gold;
    border-radius: 10px;
}
.Colored_text{
    color: #00C2FF;
}
.content_container{
display: flex;
justify-content: center;
align-items: center;
width: 100%;
flex-direction: column;
gap: 2.5rem;

}
.description_section{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    margin: 1rem 0;
    gap: 1rem;
}
.text_section{
    width: 100%;
}
.image_section{
    object-fit: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}
.text_section ul li {
    margin: 1rem 0;
    text-decoration: none;
    list-style: none;
    display: flex;
    gap: 2rem;
    align-items: center;
    font-weight: 550;
}



/* Slider css */

.slider{
    max-width:1200px !important;
    width:100% !important;
    display:flex;
    gap:1rem;
    flex-direction:column;
}
.slide_content{
    display: flex !important;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    flex-direction: column;
    height: 230px;
    border-radius: 10px;
    box-shadow: 1px 1px 5px rgb(36 39 44 / 40%);
    border: 1px solid #0000001a;
     color: #000;
}

a {
    text-decoration: none !important;
}
.slick-track{
    display: flex ;
    justify-content: center;
    gap: 1rem;
    padding-bottom:1rem ;
}

.slide_text_container{
    display: flex;
    justify-content: space-around;
    gap: 1rem;
    padding: 1rem;
  width: 100% !important;
}
.review_image_container{
  width: 15%;
  display: flex;
  align-items:center;
}
.review_image_container img  {
    width: 90px;
    height: 90px;
    border-radius: 50%;

}
.reveiw_text{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 75%;
    line-height: 1.5rem;
    font-weight: 400;
    gap: 0.5rem;
    position: relative;
    font-size: 0.9rem;
}
.review_heading{
    max-width: 1200px;
    width: 90%;
    font-size: 2.5rem;
    font-weight: 550;
    margin: 1rem 0;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding-left: 1rem;
    word-spacing: 0.5rem;
}
iframe{
    height: 400px;
    width: 100%;
    border-radius: 20px;
}

/* gallery section Images */

.gallery_container{
    max-width: 1200px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    justify-content: center;
    gap: 5px;
    margin-bottom: 10px;
    padding: 0.6rem;
    background-color: #fff;
    border-radius: 20px;  
    box-shadow: rgba(36, 39, 44, 0.33) 1px 1px 10px;

}
.image_container{
    height: auto;
    width: 100%;
}
    .image_container img {
        width: 275px;
        object-fit: fill;
        height: 350px;
        border-radius: 10px;
        margin: 0.3rem 0.5rem;
    
}
.gallery-first-section{
    display: flex;
    flex-direction: column;
    width: 50%;
}
.gallery-third {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;

}
.gallery-first{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;

}
 .gallery-second{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.small_section_first{
    display: flex;
    gap: 5px;
}
.Vedio_slide_content{
    display: flex !important;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    flex-direction: column;
    border-radius: 10px;
    height: 100% !important;
}
/* .text_gallery_section{
    height: 300px;
} */

.reveiw_text::before{
    content:url(https://admin.aventuras.co.in/uploads/quoteicon_90f33e35d5_3474151632.png);
    height: 100%;
    position: absolute;
    top: -0.5rem;
    left: -1.5rem;
}
.reveiw_text::after{
    content: url(https://admin.aventuras.co.in/uploads/quoteicon_90f33e35d5_3474151632.png);
    height: 100%;
    rotate: 180deg;
    position: absolute;
    bottom: 0;
    right: 0rem;
}



.list p {
    margin: 1.3rem !important;
    display: flex;  
    letter-spacing: 1.2px;
    align-items: center;
}
.list p::before{
    margin: 0;
    content: "\2022";
    color: #292929;
    font-size: 1.5rem;
    height: 30px;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    display: inline-flex;
    gap: 1rem;
    margin-right: 0.5rem;
}
.social_container{
    display: flex;
    align-items: center;
    justify-content: start;
    width: 100%;
    gap: 1rem;
    padding-left: 2rem;
}
.text_conatiner-sbout-us{
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        font-weight: 550;
        box-shadow: rgba(36, 39, 44, 0.33) 1px 1px 10px;
        padding: 2rem 1rem;
        border-radius: 15px;
        background: linear-gradient(146deg, #ffff 0%, #ffff 60%, gold 60%, gold) !important;
        position: relative;
}
.image_container_about-logo{
    position: absolute;
    bottom: 20%;
    right:15% ;
}
.image_container_about-logo img {
    width: 150px;
    height: auto;
} 
.regonition-card{
    height: 500px;
    width: 100%;
    box-shadow: rgba(36, 39, 44, 0.33) 1px 1px 10px;
    padding: 0.5rem;
    border-radius: 10px;

}
.regonition-card img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.regognition-container{
    display: flex;
    justify-content: space-evenly;
    gap: 1rem;
    max-width: 1200px;
    width: 100%;
}
.section_gallery{
    display: flex;

}
.pc_gallery{
display: flex;
}
.mobile_gallery{
display: none;
}
.main_slider_wrapper{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin: 2rem 0rem;
}

@media(max-width:1024px) {
    .description_section {
        width: 95%;
    }

    .text_section {
        width: 100%;
    }
    .slider{
        width: 95% !important;
    }
    .image_container_about-logo {
        bottom: 10%;
        right: 10%;
    }
    .slide_content{
        height: 100%;
        min-height: 300px;
        padding: 0.5rem;
    }
    .slide_text_container{
        flex-direction: column;
        gap: 0.5rem;
        padding: 0.5rem;
        align-items: center;
        justify-content: center;
    }
    .reveiw_text {
  
        width: 90%;
    }
    .review_image_container{
        width: 30%;
    }
    .regognition-container{
        flex-direction: column;

    }
    .pc_gallery{
        display: none;
    }
    .mobile_gallery{
            display: flex;
        }
        .image_container img{
            width: 300px;
        }
        .section{
            justify-content: space-evenly;
        }
        .gallery_container {
            width: 95%;
        }
}



@media(max-width:767px){
    .slick-track{
        gap: 0rem;
    }
    iframe {
        height: 400px;
        width: 100%;
    }
    .text_section {
        width: 100%;
    }
    .image_section{
        display: none;
    }
    .review_heading {
        font-size: 1.45rem;
        gap: 0.1rem;
        width: 100%;
        justify-content: center;
        padding-left: 0;
        gap: 0.5rem;
        flex-direction: column-reverse;
    }
    .slider {
        width: 90% !important;
    }
    .image_container{
        height: 100%;
        width: 98%;
    }
    .slick-track{
        gap: 0.35rem;
    }
    .text_section ul li {
        font-size: 0.9rem;
    }

    .slide_text_container{
        flex-direction: column;
        align-items: center;
    }
    .review_image_container{
        width: 100px;
        height: 100px;
    }


    .reveiw_text{
        text-align: center;
        font-size: 0.9rem;

    }
    .reveiw_text::before {
        top: -0.9rem;
        left: -0.5rem;
    }
    .reveiw_text::after {
        bottom: -0.5rem;
        right: -1rem;
    }
    .slide_content{
        height: 100%;
        padding: 0.5rem;
        gap: 1rem;
        min-height: 400px;
    }
    .list p{
        font-size: 0.72rem;
      
            margin: 0rem !important;
            display: flex;
            letter-spacing: normal;
            align-items: center;
    
    }
    .text_conatiner-sbout-us {
        background: #fff !important;
    }
    .image_container_about-logo img{
        display: none;
    }
    .regognition-container{
        flex-direction: column;
        padding: 0rem;
        align-items: center;
    }
    .regonition-card{
        width: 100%;
        min-width:0;
    }
    .mobile_gallery{
        display: flex;
    }
    .pc_gallery{
        display: none;
    }
    .gallery_container{
        width: 95%;
    }
    .image_container img{
        width: 145px;
        height: 200px;
    }
    .main_slider_wrapper{
        margin: 1rem 0;
        text-align: center;
    }
    .content_container {
        gap: 0.5rem;
    }
    .social_container{
        justify-content: center;
        padding-left: 0;
    }
    .banner_overlay{
        font-size: 1.5rem;
    }

}